import { Link } from '@inertiajs/react';
import { Box, Container, Grid, GridCol, Group, Image, Overlay, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import { Modal, ScrollArea } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useDisclosure } from '@mantine/hooks';

import logoGray from '@/images/logo-gray.svg';
import SocialBrands from '@/pages/site/layouts/SocialBrands.jsx';

function FooterNav() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Group align="flex-start" gap={isMobile ? 5 : 50}>
      <Stack gap={'lg'}>
        <Link href="/">
          <Text c="gfdark.4">Goldframer</Text>
        </Link>
        <Link href="/#trust">
          <Text c="gfdark.4">Trust & Transparency</Text>
        </Link>
        <Link href="/#how-to-invest">
          <Text c="gfdark.4">How to Buy Shares?</Text>
        </Link>
        <Link href="/#islamic-art-focus">
          <Text c="gfdark.4">Islamic Art Focus</Text>
        </Link>
        <Link href={route('site.faq')}>
          <Text c="gfdark.4">FAQ</Text>
        </Link>
      </Stack>
      <Stack gap={'lg'}>
        <Link href={route('site.art-sales')}>
          <Text c="gfdark.4">Art Sales</Text>
        </Link>
        <Link href={route('site.how-it-works')}>
          <Text c="gfdark.4">How It Works</Text>
        </Link>
        <Link href={route('site.about')}>
          <Text c="gfdark.4">About</Text>
        </Link>
        <a href="https://blog.goldframer.com">
          <Text c="gfdark.4">Blog</Text>
        </a>
        <Link href={route('site.contact')}>
          <Text c="gfdark.4">Contact</Text>
        </Link>
      </Stack>
      <Stack gap={'lg'}>
        <Link href={route('site.legal.risk-warning')}>
          <Text c="gfdark.4">Risk Warning</Text>
        </Link>
        <Link href={route('site.legal.privacy-policy')}>
          <Text c="gfdark.4">Privacy Policy</Text>
        </Link>
        <Link href={route('site.legal.terms')}>
          <Text c="gfdark.4">Terms of Use</Text>
        </Link>
        <Link href={route('site.legal.disclaimers')}>
          <Text c="gfdark.4">Important Disclaimers</Text>
        </Link>
        <Link href={route('site.legal.certification')}>
          <Text c="gfdark.4">Islamic Finance Certification</Text>
        </Link>
      </Stack>
    </Group>
  );
}

function Copyright({ ...props }) {
  return (
    <Stack {...props}>
      <Group>
        <SocialBrands />
      </Group>
      <Text mt={0} c="gfdark.4">
        © {new Date().getFullYear()} Goldframer. All Rights Reserved.
      </Text>
    </Stack>
  );
}

function Disclaimer() {
  const [opened, { open, close }] = useDisclosure(false);

  const firstParagraph =
    'Goldframer does not provide any financial advice. None of the information that Goldframer provides on the Goldframer Platform or the websites should be regarded as "investment advice" or "recommendation" regarding a course of action, including without limitation, as those terms are used in any applicable law or regulations. Information provided on the Goldframer Platform or the websites is provided with the understanding that (1) Goldframer is not acting in a fiduciary or advisory capacity under any contract with you, or any applicable law or regulation, (2) you will make your own independent decision with respect to any course of action in connection herewith as to whether such course of action is appropriate or proper based on your own judgment and your specific circumstances and objectives, (3) you are capable of understanding and assessing the merits of a course of action and evaluating investment risks independently.';

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <Overlay
        onClick={open}
        gradient="linear-gradient(0deg, rgba(21, 46, 64, 1) 0%, rgba(21, 46, 64, 0) 100%)"
        opacity={1}
        style={{ cursor: 'pointer' }}
      />

      <Modal size={'xl'} opened={opened} onClose={close} title="Important Disclaimers" scrollAreaComponent={ScrollArea.Autosize}>
        <p>{firstParagraph}</p>
        {opened && (
          <>
            <p>
              Goldframer does not purport to and does not, in any fashion, provide tax, accounting, actuarial, recordkeeping,
              legal, broker/dealer or any related services. You should consult your own advisors with respect to these areas and
              any material with regards to investment decisions. You may not rely on the material contained herein. Goldframer
              shall not have any liability for any damages of any kind whatsoever relating to this material.
            </p>
            <p>
              Goldframer is not a financial institution and is not licensed by or under the supervision of any financial
              supervisory authority and does not provide any licensed financial services such as investment services, capital
              raising, fund management, management of a collective investment scheme or investment advice.
            </p>
            <p>
              The website is provided "as is" and on an "as available" basis and we give no warranty that it will be free from
              defects and/or faults. We make no warranty or representation (express or implied) that it will be fit for any
              particular purposes, that it will not infringe the rights of third parties, that it will be compatible with all
              systems, that it will be secure or that all information provided will be continuous, uninterrupted, timely, accurate
              or error-free. Goldframer reserves the right to change any and all content on the Goldframer Platform and on the
              websites and any services offered at any time without notice. Reference to any products, services, processes or
              other information by trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement,
              sponsorship or recommendation thereof, or any affiliation with Goldframer (unless so expressly stated to be so).
            </p>
            <p>
              The Goldframer Platform and the websites may be temporarily unavailable from time to time for maintenance or other
              reasons. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or unauthorised access to, or alteration of your
              communication.
            </p>
            <p>
              There are no platform tokens associated with the Goldframer. The tokens are not intended to be used as a means of
              payment or similar.
            </p>
            <p>
              Nothing on this website should be construed as an offer to sell, solicitation of an offer to buy or a recommendation
              in respect of a security. You are solely responsible for determining whether any investment, investment strategy or
              related transaction is appropriate for you based on your personal investment objectives, financial circumstances and
              risk tolerance. You should consult with licensed legal professionals and investment advisors for any legal, tax,
              insurance or investment advice.
            </p>
            <p>
              No information published on the Goldframer Website constitutes a solicitation or offer, or recommendation, to buy or
              sell any digital assets, investment instruments, to effect any transactions, or to conclude any legal act of any
              kind whatsoever.
            </p>
            <p>
              <b>Forward-looking statements</b>
            </p>
            <p>
              This Website may contain forward looking statements and may be subject to change. The opinions expressed herein are
              those of Goldframer, its affiliates and partners at the time of writing. The information and analyses contained in
              this Website, such as for example financial market data, market prices and any other financial market information,
              are based upon or by reference to carefully selected sources which are believed to be reliable. However, Goldframer
              makes no representation as to its reliability or completeness and disclaims all liability for losses arising from
              the use of this information. Past performance is not an indication or guarantee of future performance of a product
              or underlying asset. The value of any investment may be subject to fluctuations and, in some circumstances,
              investors may not recover the original amount invested. Exchange rate fluctuations may also cause the value of an
              investment to rise or fall. No information published on the Goldframer Website should therefore be understood as an
              indication of the future development of any financial instrument, cryptocurrency, digital asset, security or
              whatsoever.
            </p>
          </>
        )}
      </Modal>

      <Container style={{ width: '100%' }}>
        <Box c={'gfdark.6'}>
          <Text fz={rem(20)}>Important Disclaimers</Text>
          <Text mt={'md'}>{firstParagraph}</Text>
          <Text
            onClick={open}
            c={'gfdark.6'}
            fz={rem(16)}
            style={{ cursor: 'pointer', position: 'absolute', bottom: '15px', zIndex: 200 }}
          >
            Read More
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

export default function SiteFooter(props) {
  // mantine way is mobile check
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <Stack mih={'var(--footer-height)'} bg={'url(/img/pattern.png) #152E40'} {...props} align={'stretch'} justify="flex-end">
      <Container style={{ width: '100%' }} py={60}>
        <Grid gutter={'xl'} justify="space-between">
          <GridCol span={{ base: 12, md: 'content' }} order={{ md: 2 }}>
            <Stack align={isMobile ? 'flex-start' : 'flex-end'}>
              <Link href={'/'}>
                <Image w={140} src={logoGray} />
              </Link>

              <Copyright mt={60} visibleFrom={'md'} align={'flex-end'} />
            </Stack>
          </GridCol>
          <GridCol span="content" order={{ md: 1 }}>
            <FooterNav />

            <Copyright mt={60} hiddenFrom={'md'} />
          </GridCol>
        </Grid>
      </Container>
      <Disclaimer />
    </Stack>
  );
}
